import Button from '../ui/Button';
import CustomMobileMap from './CustomMobileMa';
import style from './offer/Offer.module.scss';
import emailjs from 'emailjs-com';
import { useState } from 'react';

const Offer = () => {
  const [name, setName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [mail, setMail] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [error, setError] = useState<string | undefined>(undefined);
  const [color, setColor] = useState<string>('red');

  const sendMail = () => {
    emailjs.send(
      'service_7ws57h6',
      'template_bd4af0f',
      {
        name: name,
        surname: surname,
        number: number,
        message: message,
        mail: mail,
      },
      'TpuEfOpaEmn-tWq-8'
    );
  };
  return (
    <div id="offer" className={style.offer}>
      <h1 className={style.title}>Zbavte sa svojich problémov</h1>
      <div className={style.text}>Objednajte sa na individuálne sedenie</div>
      <form>
        <div className={style.topWrapper}>
          <div className={style.nameWrapper}>
            <label>Meno:</label>
            <br />
            <input
              type="text"
              name="name"
              id="ahoj"
              required
              placeholder="Meno"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <br />
          </div>
          <div className={style.surnameWrapper}>
            <label>Priezvisko:</label>
            <br />
            <input
              type="text"
              name="surname"
              id="ahoj"
              required
              placeholder="Priezvisko"
              value={surname}
              onChange={(e) => {
                setSurname(e.target.value);
              }}
            />
            <br />
          </div>
        </div>
        <div className={style.bottomWrapper}>
          <div className={style.numberWrapper}>
            <label>Telefónne číslo:</label>
            <br />
            <input
              type="tel"
              name="number"
              id="ahoj"
              required
              placeholder="Telefónne číslo"
              value={number}
              onChange={(e) => {
                setNumber(e.target.value);
              }}
            />
            <br />
          </div>
          <div className={style.mailWrapper}>
            <label>E-Mail:</label>
            <br />
            <input
              type="email"
              name="mail"
              id="ahoj"
              required
              placeholder="Mail"
              value={mail}
              onChange={(e) => {
                setMail(e.target.value);
              }}
            />{' '}
            <br />
          </div>
        </div>
        <div className={style.messageWrapper}>
          <label>Správa:</label>
          <br />
          <textarea
            name="message"
            id="ahoj"
            required
            placeholder="Správa"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />{' '}
          <br />
        </div>
      </form>
      {error && (
        <div style={{ color: color }} className={style.error}>
          {error}
        </div>
      )}
      <Button
        onClick={() => {
          setColor('red');
          if (name !== '') {
            if (surname !== '') {
              if (number !== '') {
                if (mail !== '') {
                  if (message !== '') {
                    sendMail();
                    setName('');
                    setSurname('');
                    setNumber('');
                    setMessage('');
                    setMail('');
                    setColor('green');
                    setError('Email odoslaný');
                  } else {
                    setError('Prosím vyplň Správu');
                  }
                } else {
                  setError('Prosím vyplň E-Mail');
                }
              } else {
                setError('Prosím vyplň Telefónne číslo');
              }
            } else {
              setError('Prosím vyplň Priezvisko');
            }
          } else {
            setError('Prosím Zadaj Meno');
          }
        }}
        className={style.btn}
      >
        Odoslať
      </Button>
      <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
        <div
          style={{
            width: '100%',
            fontSize: '32px',
            fontWeight: 'bolder',
            marginTop: '20px',
            marginBottom: '40px',
          }}
        >
          Kontakt
        </div>
        <div
          style={{
            width: '50%',
            fontSize: '20px',
            fontWeight: 'normal',
            lineHeight: '30px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div>Masáže Denisa</div> <div>Centrum 90301 Senec</div>{' '}
          <div>Mgr. Denisa Bábiková</div>
          <div>Mail: denisababikova@gmail.com</div> <div>+421 949 883 443</div>
          <div>pracujeme na objednávku</div>
          <div>+ dohodou u vás doma</div>
          <div>PO-PIA 8:00-20:00 </div>
          <div>SO-NE 10:00-20:00 </div>
        </div>
        <div style={{ width: '50%', height: '300px' }}>
          <CustomMobileMap
            latitude={48.219251323701606}
            longtitude={17.399510053489326}
            zoom={14}
          />
        </div>
      </div>
    </div>
  );
};

export default Offer;
